import { action } from 'mobx';
import {
  type ResolvedRouteLocation,
  type RoutingInfo,
  PageRouteHandlers,
} from '@lib/routing';
import { assert } from '@repo-lib/utils-core';
import routingStore from '@my-breteuil/store/routing/RoutingStore';
import { PropertyFavoriteStatus, UserGoalPeriod } from '@core/api/types';

import * as Routes from '@my-breteuil/store/routing/routes';
import { RootPages } from '@my-breteuil/store/routing/pages';

import { isClientError } from '@core/api/graphql/gen-v2';
import session from '@my-breteuil/store/ui/common/Session';

import mainStore from '@my-breteuil/store/ui/Main';
import geoAreasStore from '@my-breteuil/store/ui/common/geo-areas';
import personalSpaceStore from '@my-breteuil/store/ui/pages/personal-space/Main';
import foldersStore from '@my-breteuil/store/ui/pages/folders/Main';
import analyticsStore from '@my-breteuil/store/ui/pages/Analytics';
import showcaseMediasStore from '@my-breteuil/store/ui/pages/showcase/medias-list';
import showcaseStreamsStore from '@my-breteuil/store/ui/pages/showcase/streams-list';
import showcaseStreamMixingStore from '@my-breteuil/store/ui/pages/showcase/stream-mixing';
import showcaseScreensStore from '@my-breteuil/store/ui/pages/showcase/screens-list';
import showcaseScreensStatusStore from '@my-breteuil/store/ui/pages/showcase/screens-status';
import heatMapStore from '@my-breteuil/store/ui/pages/market-data/heat-map';
import agenciesStore from '@my-breteuil/store/ui/pages/agencies';
import usersStore, { GroupView } from '@my-breteuil/store/ui/pages/users';
import configVariablesStore from '@my-breteuil/store/ui/pages/config-variables';
import priceAdjustmentStore from '@my-breteuil/store/ui/pages/market-data/price-adjustment';
import propertiesStore from '@my-breteuil/store/ui/pages/properties';
import propertyStore, { RouteParamToPropertyTab } from '@my-breteuil/store/ui/pages/property';
import valuationsStore from '@my-breteuil/store/ui/pages/valuations';
import contactsBuyersStore from '@my-breteuil/store/ui/pages/contacts/buyers';
import contactsOwnersStore from '@my-breteuil/store/ui/pages/contacts/owners';
import contactStore, { RouteParamToContactTab } from '@my-breteuil/store/ui/pages/contact';
import contactCreateStore from '@my-breteuil/store/ui/pages/contact/create';
import logsStore, { RouteParamToLogsType } from '@my-breteuil/store/ui/pages/logs';
import profileStore, { sortUserTasksRecap } from '@my-breteuil/store/ui/pages/profile';
import profileUsersGoalsStore from '@my-breteuil/store/ui/pages/profile/usersGoals';
import profileUsersAnalyticsStore from '@my-breteuil/store/ui/pages/profile/usersAnalytics';
import profileUsersRemunerationAnalyticsStore from '@my-breteuil/store/ui/pages/profile/usersRemunerationAnalytics';
import dayOffRequestsStore from '@my-breteuil/store/ui/pages/profile/dayOffRequests';
import resetPasswordStore from '@my-breteuil/store/ui/pages/reset-password';
import offersStore from '@my-breteuil/store/ui/pages/offers';
import prospectingStore from '@my-breteuil/store/ui/pages/prospecting';
import appTopBarStore from '@my-breteuil/store/ui/common/app-top-bar';
import globalConfigVariableStore from '@my-breteuil/store/ui/common/config-variables';
import valuationStore from '@my-breteuil/store/ui/pages/valuation';
import holidaysStore from '@my-breteuil/store/ui/pages/holidays';
import valuationsPDFsStore from '@my-breteuil/store/ui/pages/valuations-pdfs';
import usersAPIRequestsStore from '@my-breteuil/store/ui/pages/users-api-requests';
import dailyReportBannerStore from '@my-breteuil/store/ui/common/daily-report-banner';
import entityLogsStore from '@my-breteuil/store/ui/pages/entity-logs';
import propertiesConfidentialLinksStore from '@my-breteuil/store/ui/pages/properties-confidential-links';

export default function setHandlers(handlers: PageRouteHandlers<RootPages>)
{
  const Public = handlers.createHandlersScope();

  const Authenticated = Public.extend(async (location, info) => {
    await mainStore.sessionPromise;
    if (session.loggedIn)
    {
      await geoAreasStore.init();
      await globalConfigVariableStore.configVariables.ensureSuccess();
      if (session.mybUser?.id)
        appTopBarStore.userGoals.ensureSuccess({ userId: session.mybUser.id });
      dailyReportBannerStore.init();
      return null;
    }
    return action(() => {
      routingStore.loginRedirectRoute = location.locationString;
      return { page: RootPages.LogIn, route: Routes.logIn.generateStaticPath() };
    });
  }, (error) => {
    if (isClientError(error) && error.code === 'RemoteAccessForbidden')
      return () => ({ page: RootPages.RemoteAccessForbidden, route: null });
    throw error;
  });

  const Agent = Authenticated.extend((location, info) => {
    if (session.agent)
      return null;
    return () => ({ page: RootPages.ErrorPermissionRequired, route: null });
  });

  const AgentOrPropertyManager = Authenticated.extend((location, info) => {
    if (session.agent || session.propertyManager)
      return null;
    return () => ({ page: RootPages.ErrorPermissionRequired, route: null });
  });

  const Manager = Agent.extend((location, info) => {
    if (session.manager)
      return null;
    return () => ({ page: RootPages.ErrorPermissionRequired, route: null });
  });

  const Accounting = Authenticated.extend((location, info) => {
    if (session.accountant)
      return null;
    return () => ({ page: RootPages.ErrorPermissionRequired, route: null });
  });

  const ManagerOrAccounting = Authenticated.extend((location, info) => {
    if (session.manager || session.accountant)
      return null;
    return () => ({ page: RootPages.ErrorPermissionRequired, route: null });
  });

  const Admin = Authenticated.extend((location, info) => {
    if (session.admin)
      return null;
    return () => ({ page: RootPages.AdminRestrictedArea, route: null });
  });

  const Root = Public.setStaticPage(RootPages.NotFound/*Redirects will prevent this*/, Routes.root, async (location, info) => {
    await mainStore.sessionPromise;

    //Keep this in sync with RoutingLogic.getDefaultRedirectInfo
    //TODO: find a way to deduplicate things
    if (!session.loggedIn)
      return handlers.redirect(LogIn, location, info);
    if (session.accountant && !session.admin)
      return handlers.redirect(AgentsBlueprintAccounting, location, info);
    if (session.propertyManager && !session.admin)
      return handlers.redirect(PropertiesConfidentialLinks, location, info);
    return handlers.redirect(Profile, location, info);
  });

  const LogIn = Public.setStaticPage(RootPages.LogIn, Routes.logIn, async (location, info) => {
    await mainStore.sessionPromise;

    if (session.loggedIn)
      return handlers.redirect(Root, location, info);
    return action(() => {
      routingStore.loginRedirectRoute = null;
      return null;
    });
  });

  Public.setPage(RootPages.ResetPassword, Routes.resetPassword, async (location, info) => {
    const { routeParams } = location;
    resetPasswordStore.setEmailToken(routeParams.passwordResetTokenEmail);
    return null;
  });

  Agent.setPage(RootPages.PersonalSpace, Routes.personalSpace, async () => {
    await personalSpaceStore.refreshFolders();
    return null;
  });

  Agent.setPage(RootPages.Properties, Routes.properties, async (location, info) => {
    const { searchParams } = location;

    function IntFilter<FilterType>(formatFilter: (value: number) => FilterType)
    {
      return (param: string | null | undefined): FilterType | null => {
        if (!param)
          return null;
        const value = Number.parseInt(param);
        if (isNaN(value))
          return null;
        return formatFilter(value);
      };
    }

    function IntListFilter<FilterType>(formatFilter: (value: Array<number>) => FilterType)
    {
      return (param: string | null | undefined): FilterType | null => {
        if (!param)
          return null;
        const values = param.split(',').map(value => Number.parseInt(value)).filter(value => !isNaN(value));
        if (values.length === 0)
          return null;
        return formatFilter(values);
      };
    }

    function StringListFilter<StringType extends string, FilterType>(
      formatFilter: (value: Array<StringType>) => FilterType,
      opts: {
        isValid?: ((value: string) => boolean) | undefined,
      } = {},
    )
    {
      return (param: string | null | undefined): FilterType | null => {
        if (!param)
          return null;
        const isValid = opts.isValid || ((s) => (s.length > 0));
        const values = param.split(',').filter(isValid) as Array<StringType>;
        if (values.length === 0)
          return null;
        return formatFilter(values);
      };
    }

    function assignFilters(baseFilters, addedFilters)
    {
      for (const key in addedFilters)
      {
        if ((
          baseFilters[key] !== undefined
        ) && (
          baseFilters[key] !== null && typeof baseFilters[key] === 'object'
        ) && (
          addedFilters[key] !== null && typeof addedFilters[key] === 'object'
        ))
          assignFilters(baseFilters[key], addedFilters[key]);
        else
          baseFilters[key] = addedFilters[key];
      }
      return baseFilters;
    }

    const paramParsers = {
      pricePerSurfaceMin: IntFilter((value) => ({ pricePerSurface: { gte: value }})),
      pricePerSurfaceMax: IntFilter((value) => ({ pricePerSurface: { lte: value }})),
      geoAreaIds: IntListFilter((ids) => ({ geoAreaId: ids })),
      favoriteStatus: StringListFilter((statuses: Array<PropertyFavoriteStatus>) => ({
        favoriteStatus: { in: statuses },
      }), {
        isValid: (value) => PropertyFavoriteStatus[value] !== undefined,
      }),
    };

    const newFilter = { ...propertiesStore.defaultFilter };
    for (const [paramName, parser] of Object.entries(paramParsers))
    {
      const paramValue = searchParams.get(paramName);
      const addFilters = parser(paramValue);
      if (addFilters !== null)
        assignFilters(newFilter, addFilters);
    }

    propertiesStore.setFilter(newFilter);
    await propertiesStore.refresh();

    return () => ({
      page: RootPages.Properties,
      route: Routes.properties.generateStaticPath(),
    });
  });

  Agent.setStaticPage(RootPages.Property, Routes.createProperty, async (location, info) => {
    propertyStore.reset();

    const contactIdParam = location.searchParams.get('contactId');
    const contactId = contactIdParam ? parseInt(contactIdParam) : null;
    if (contactId && !isNaN(contactId))
      await propertyStore.defaultContact.ensureSuccessReload({ id: contactId });

    await propertyStore.refreshResources();
    return null;
  });

  Agent.setPage(RootPages.Property, Routes.property, async (location, info) => {
    const { routeParams, searchParams } = location;
    const { propertySlug } = routeParams;
    const propertyMainInfo = await propertyStore.refresh({ propertySlug, searchParams });
    if (!propertyMainInfo)
      return () => ({ page: RootPages.NotFound });
    return null;
  });

  Agent.setPage(RootPages.Property, Routes.propertyWithTab, async (location, info) => {
    const { routeParams, searchParams } = location;
    const { propertySlug, tab: tabParam } = routeParams;
    const tab = RouteParamToPropertyTab.get(tabParam);
    const propertyMainInfo = await propertyStore.refresh({ propertySlug, tab, searchParams });
    if (!propertyMainInfo)
      return () => ({ page: RootPages.NotFound });
    if (tab === undefined)
    {
      return () => ({
        page: RootPages.Property,
        route: Routes.propertyWithTab.generatePath({ propertySlug, tab: 'info' }),
      });
    }
    return null;
  });

  Agent.setPage(RootPages.Buyers, Routes.buyers, async () => {
    await contactsBuyersStore.refresh();
    return null;
  });

  Agent.setPage(RootPages.Buyers, Routes.contacts, async () => {
    await contactsBuyersStore.refresh();
    return () => ({
      page: RootPages.Buyers,
      route: Routes.buyers.generateStaticPath(),
    });
  });

  Agent.setPage(RootPages.Owners, Routes.owners, async () => {
    await contactsOwnersStore.refresh();
    return null;
  });

  Manager.setPage(RootPages.Logs, Routes.logs, async (location, info) => {
    const logsType = RouteParamToLogsType.get(location.routeParams.logsType);
    if (logsType === undefined)
    {
      return () => ({
        page: RootPages.Logs,
        route: Routes.logs.generatePath({ logsType: 'valuations' }),
      });
    }
    logsStore.logsType = logsType;
    await logsStore.refresh();
    return null;
  });

  const Profile = Agent.setStaticPage(RootPages.Profile, Routes.profile, async (location, info) => {
    assert(session.mybUser);
    const initialData = await profileStore.initialData.ensureSuccessReload({
      userId: session.mybUser.id,
      period_userTasksRecap: profileStore.periodFilter,
      period_userTasksRecapMonthly: UserGoalPeriod.Month,
      period_userGoalsMonthly: UserGoalPeriod.Month,
      period_userDailyReportStats: UserGoalPeriod._7Day,
      period_userDailyReportStatsDay: UserGoalPeriod.Day,
      period_userDailyReportStats3m: UserGoalPeriod._63Day,
      startDate_usersGoals: profileUsersGoalsStore.startDate,
      endDate_usersGoals: profileUsersGoalsStore.endDate,
    });

    profileStore.managedUsers.setResult(initialData.managedUsers);
    profileStore.managedUsersHistorical.setResult(initialData.managedUsersHistorical);
    profileStore.agenciesStats.setResult(initialData.agenciesStats);
    profileStore.userTasksRecap.setResult(sortUserTasksRecap(initialData.userTasksRecap));
    profileStore.dailyReport.setResult(initialData.userDailyReport);
    profileStore.dailyReportStats.setResult(initialData.userDailyReportStats);
    profileStore.dailyReportStatsDay.setResult(initialData.userDailyReportStatsDay);
    profileStore.dailyReportStats3m.setResult(initialData.userDailyReportStats3m);
    profileStore.userGoals.setResult({
      reminders: initialData.userGoalsMonthly_reminders,
      successfulReminders: initialData.userGoalsMonthly_successfulReminders,
      successfulPhoneReminders: initialData.userGoalsMonthly_successfulPhoneReminders,
      successfulWhatsAppReminders: initialData.userGoalsMonthly_successfulWhatsAppReminders,
      acceptedOffers: initialData.userGoalsMonthly_acceptedOffers,
      madeOffer: initialData.userGoalsMonthly_madeOffer,
      mandates: initialData.userGoalsMonthly_mandates,
      viewings: initialData.userGoalsMonthly_viewings,
      valuations: initialData.userGoalsMonthly_valuations,
      prospectedStreets: initialData.userGoalsMonthly_prospectedStreets,
    });
    profileStore.userGoalsTasksRecap.setResult(sortUserTasksRecap(initialData.userTasksRecapMonthly));

    dayOffRequestsStore.dayOffRequests.setResult(initialData.dayOffRequests);
    dayOffRequestsStore.dayOffBalances.setResult(initialData.userDayOffBalances);

    profileUsersGoalsStore.usersGoalsFetchable.setResult(initialData.usersGoals);

    profileUsersAnalyticsStore.usersAnalytics.setResult(initialData.usersAnalytics);

    profileUsersRemunerationAnalyticsStore.usersRemunerationAnalytics.setResult(initialData.usersRemunerationAnalytics);

    const dayOffRequestIdRaw = location.searchParams.get('dayOffRequestId');
    const requestId = dayOffRequestIdRaw ? parseInt(dayOffRequestIdRaw) : null;
    if (requestId && !isNaN(requestId))
    {
      await dayOffRequestsStore.userDayOffRequestDialogStore.open(requestId);
    }
    return null;
  });

  Agent.setPage(RootPages.ContactCreate, Routes.createContact, async (location, info) => {
    contactCreateStore.reset();
    return null;
  });

  Agent.setPage(RootPages.Contact, Routes.contact, async (location, info) => {
    const { routeParams } = location;
    const contactId = Number.parseInt(routeParams.contactId);

    if (Number.isNaN(contactId))
      return () => ({
        page: RootPages.NotFound,
      });
    const contact = await contactStore.init(contactId);
    if (!contact)
      return () => ({
        page: RootPages.NotFound,
      });

    return () => ({
      page: RootPages.Contact,
      route: Routes.contactWithTab.generatePath({ contactId, tab: 'info' }),
    });
  });

  Agent.setPage(RootPages.Contact, Routes.contactWithTab, async (location, info) => {
    const { routeParams } = location;
    const contactId = Number.parseInt(routeParams.contactId);
    const tab = RouteParamToContactTab.get(routeParams.tab);

    if (Number.isNaN(contactId) || !tab)
      return () => ({
        page: RootPages.NotFound,
      });
    const contact = await contactStore.init(contactId, tab);
    if (!contact)
      return () => ({
        page: RootPages.NotFound,
      });

    return null;
  });

  Admin.setPage(RootPages.Folders, Routes.folders, async () => {
    await foldersStore.refresh();
    return null;
  });

  Admin.setPage(RootPages.Analytics, Routes.analytics, async () => {
    await analyticsStore.refresh();
    return null;
  });

  Manager.setPage(RootPages.ShowcaseMediasList, Routes.showcaseMediasList, async () => {
    await showcaseMediasStore.refresh();
    return null;
  });

  const ShowcaseStreamsListBinding = Manager.setStaticPage(
    RootPages.ShowcaseStreamsList,
    Routes.showcaseStreamsList,
    async () => {
      await showcaseStreamsStore.refresh();
      return null;
    },
  );

  Manager.setPage(RootPages.ShowcaseStreamMixing, Routes.showcaseStreamMixing, async (location, info) => {
    const { routeParams } = location;
    const streamId = Number.parseInt(routeParams.streamId);
    if (streamId)
    {
      await showcaseStreamMixingStore.refresh(streamId);
      if (showcaseStreamMixingStore.stream)
        return null;
    }
    return handlers.redirect(ShowcaseStreamsListBinding, location, info);
  });

  Manager.setPage(RootPages.ShowcaseScreensList, Routes.showcaseScreensList, async () => {
    await showcaseScreensStore.refresh();
    return null;
  });

  Manager.setPage(RootPages.ShowcaseScreensStatus, Routes.showcaseScreensStatus, async () => {
    await showcaseScreensStatusStore.refresh();
    return null;
  });

  ManagerOrAccounting.setPage(RootPages.Offers, Routes.offers, async () => {
    await offersStore.refresh();
    return null;
  });

  Admin.setPage(RootPages.Agencies, Routes.agencies, async () => {
    await agenciesStore.refresh();
    return null;
  });

  Manager.setPage(RootPages.UsersIT, Routes.usersIT, async () => {
    await usersStore.refresh(GroupView.IT);
    return null;
  });

  Manager.setPage(RootPages.UsersHR, Routes.usersHR, async () => {
    await usersStore.refresh(GroupView.HR);
    return null;
  });

  Manager.setPage(RootPages.UsersGoals, Routes.usersGoals, async () => {
    await usersStore.refresh(GroupView.Goals);
    return null;
  });

  // TODO Change back to Manager when we have a proper accounting page
  const AgentsBlueprintAccounting = Accounting.setStaticPage(RootPages.UsersAccounting, Routes.usersAccounting, async () => {
    await usersStore.refresh(GroupView.Accounting);
    return null;
  });

  Admin.setPage(RootPages.ConfigVariables, Routes.configVariables, async () => {
    await configVariablesStore.refresh();
    return null;
  });

  Admin.setPage(RootPages.MarketDataTransactions, Routes.marketDataTransactions, async () => {
    // const { areaFilter, ...filter } = transactionsFilters.queryFiltersWithoutDistricts;
    // //TODO: handle areaFilter
    // const cities = await GetCitiesInfo({ filter });
    // await transactions.init();
    // await transactionGlobalFilter.fetchCurrentFilter();
    // const citiesIds = cities.data.edges.map(({ id }) => id);
    // if (citiesIds.length === 0)
    // {
    //   //No cities returned by the server; should never happen
    //   throw new Error('Invalid server response: no cities returned');
    // }
    // const areas = await Areas({});
    // const areasIds = areas.data.edges.map(({ id }) => id);
    // return action(() => {
    //   transactionsFilters.selectableCitiesIds.replace(citiesIds);
    //   transactionsFilters.selectableAreasIds.replace(areasIds);
    //   if (transactionsFilters.cityId === undefined || !citiesIds.includes(transactionsFilters.cityId))
    //     transactionsFilters.cityId = citiesIds[0];
    //   transactions.refreshTransactions();
    //   return null;
    // });
    return null;
  });

  Manager.setPage(RootPages.MarketDataHeatMap, Routes.marketDataHeatMap, async () => {
    await heatMapStore.refresh();
    return null;
  });

  Manager.setPage(RootPages.MarketDataPriceAdjustment, Routes.marketDataPriceAdjustment, async () => {
    await priceAdjustmentStore.refresh();
    return null;
  });

  Admin.setPage(RootPages.Valuations, Routes.valuations, async () => {
    await valuationsStore.refresh();
    return null;
  });

  Agent.setPage(RootPages.Prospecting, Routes.prospecting, async () => {
    await prospectingStore.refresh();
    return null;
  });

  async function valuationHandler(location: ResolvedRouteLocation, info: RoutingInfo)
  {
    const { routeParams, searchParams } = location;
    const propertySlug = routeParams.propertySlug;
    const userIdRaw = searchParams.get('userId');
    const userId = userIdRaw ? parseInt(userIdRaw) : null;
    await valuationStore.refresh(propertySlug, userId);
    if (!valuationStore.valuationPdf.lastResult && userId !== null)
      return () => ({ page: RootPages.NotFound });
    valuationStore.setPreview(false);
    return null;
  }

  Agent.setPage(RootPages.Valuation, Routes.valuation, valuationHandler);
  Agent.setPage(RootPages.ValuationPreview, Routes.valuationPreview, valuationHandler);

  Manager.setPage(RootPages.Holidays, Routes.holidays, async (location, info) => {
    await holidaysStore.refresh();
    return null;
  });

  Manager.setPage(RootPages.ValuationsPDFs, Routes.valuationsPDFs, async (location, info) => {
    await valuationsPDFsStore.refresh();
    return null;
  });

  Admin.setPage(RootPages.UsersAPIRequests, Routes.usersAPIRequests, async (location, info) => {
    await usersAPIRequestsStore.refresh();
    return null;
  });

  Manager.setPage(RootPages.EntityLogs, Routes.entityLogs, async (location, info) => {
    await entityLogsStore.refresh();
    return null;
  });

  const PropertiesConfidentialLinks = AgentOrPropertyManager.setStaticPage(RootPages.PropertiesConfidentialLinks, Routes.propertiesConfidentialLinks, async (location, info) => {
    await propertiesConfidentialLinksStore.refresh();
    return null;
  });
}
