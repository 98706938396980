import type { IdType } from '@repo-breteuil/common-definitions';
import type {
  MyBreteuilUserContractTypeEnum,
  MyBreteuilUserAddressBookSyncingType,
} from '@core/api/types';
import type { User } from './shared';

import { mutation, errorWrapper } from '@core/api/graphql/gen-v2';
import { UserFields } from './shared';

export interface UpdateUserArgs
{
  id: IdType,
  // - IT
  password?: string | null | undefined,
  currentPassword?: string | null | undefined,
  login?: string | null | undefined,
  email?: string | null | undefined,
  firstname?: string | null | undefined,
  lastname?: string | null | undefined,
  phoneMobile?: string | null | undefined,
  phoneDirectLine?: string | null | undefined,
  phoneFallbackAgency?: string | null,
  phoneFallbackPrestige?: string | null,
  remoteAccess?: boolean | null | undefined,
  remoteAccessUntil?: number | null/*timestamp*/ | undefined,
  addressBookSyncingType?: MyBreteuilUserAddressBookSyncingType | null | undefined,
  accountDisabled?: boolean | null | undefined,
  photo?: File | null | undefined,
  // - HR
  monthlyAdvance?: number | null | undefined,
  monthlySalary?: number | null | undefined,
  contractType?: MyBreteuilUserContractTypeEnum | null | undefined,
  arrivedAt?: number | null/*timestamp*/ | undefined,
  leavedAt?: number | null/*timestamp*/ | undefined,
  remunerationGuaranteedPercentage?: number | null | undefined,
  remunerationGuaranteeEndDate?: number | null/*timestamp*/ | undefined,
  remunerationT1Threshold?: number | null | undefined,
  remunerationT1Percentage?: number | null | undefined,
  remunerationT2Threshold?: number | null | undefined,
  remunerationT2Percentage?: number | null | undefined,
  remunerationT3Percentage?: number | null | undefined,
  hr_remunerationComment?: string | null | undefined,
  hr_interviewedHR?: boolean | null | undefined,
  hr_interviewedArsene?: boolean | null | undefined,
  hr_interviewedArthur?: boolean | null | undefined,
  hr_interviewedManager?: boolean | null | undefined,
  hr_onboardingCost?: number | null | undefined,
  hr_offBoardingCost?: number | null | undefined,
  hr_expectedTurnover12m?: number | null | undefined,
  hr_yearsOfExperience?: number | null | undefined,
  hr_previousEmployer?: string | null | undefined,
  hr_stayingProbability?: number | null | undefined,
  hr_trialPeriodEndDate?: number | null/*timestamp*/ | undefined,
  goals_comment?: string | null | undefined,
  goals_weekComment?: string | null | undefined,
  goals_quarterComment?: string | null | undefined,
  goals_yearComment?: string | null | undefined,
  goals_acceptedOffersHT?: number | null | undefined,
  goals_madeOffers?: number | null | undefined,
  goals_viewings?: number | null | undefined,
  goals_successfulReminders?: number | null | undefined,
  goals_successfulWhatsAppReminders?: number | null | undefined,
  goals_mandates?: number | null | undefined,
  goals_valuations?: number | null | undefined,
  goals_prospectedStreets?: number | null | undefined,
  vipContactsLimit?: number | null | undefined,
  buyerContactsLimit?: number | null | undefined,
  silae_EId?: string | null | undefined,
}

export default function UpdateUser(args: UpdateUserArgs)
{
  return mutation<User>({
    operationName: 'Users_UpdateUser',
    variables: {
      id: 'Int!',
      // - IT
      password: 'String',
      currentPassword: 'String',
      login: 'String',
      email: 'String',
      firstname: 'String',
      lastname: 'String',
      phoneMobile: 'String',
      phoneDirectLine: 'String',
      phoneFallbackAgency: 'String',
      phoneFallbackPrestige: 'String',
      remoteAccess: 'Boolean',
      remoteAccessUntil: 'Date',
      addressBookSyncingType: 'MyBreteuilUserAddressBookSyncingType',
      accountDisabled: 'Boolean',
      photo: 'Upload',
      // - HR
      monthlyAdvance: 'Float',
      monthlySalary: 'Float',
      contractType: 'MyBreteuilUserContractTypeEnum',
      arrivedAt: 'Date',
      leavedAt: 'Date',
      remunerationGuaranteedPercentage: 'Float',
      remunerationGuaranteeEndDate: 'Date',
      remunerationT1Threshold: 'Int',
      remunerationT1Percentage: 'Float',
      remunerationT2Threshold: 'Int',
      remunerationT2Percentage: 'Float',
      remunerationT3Percentage: 'Float',
      hr_remunerationComment: 'String',
      hr_interviewedHR: 'Boolean',
      hr_interviewedArsene: 'Boolean',
      hr_interviewedArthur: 'Boolean',
      hr_interviewedManager: 'Boolean',
      hr_onboardingCost: 'Float',
      hr_offBoardingCost: 'Float',
      hr_expectedTurnover12m: 'Float',
      hr_yearsOfExperience: 'Int',
      hr_previousEmployer: 'String',
      hr_stayingProbability: 'Float',
      hr_trialPeriodEndDate: 'Date',
      // - Monhtly goals
      goals_comment: 'String',
      goals_weekComment: 'String',
      goals_quarterComment: 'String',
      goals_yearComment: 'String',
      goals_acceptedOffersHT: 'Int',
      goals_madeOffers: 'Int',
      goals_viewings: 'Int',
      goals_successfulReminders: 'Int',
      goals_successfulWhatsAppReminders: 'Int',
      goals_mandates: 'Int',
      goals_valuations: 'Int',
      goals_prospectedStreets: 'Int',
      vipContactsLimit: 'Int',
      buyerContactsLimit: 'Int',
      // Accounting
      silae_EId: 'String',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilUser: {
          update: [{
            args: {
              id: true,
              password: true,
              currentPassword: true,
              login: true,
              email: true,
              firstname: true,
              lastname: true,
              phoneMobile: true,
              phoneDirectLine: true,
              phoneFallbackAgency: true,
              phoneFallbackPrestige: true,
              remoteAccess: true,
              remoteAccessUntil: true,
              addressBookSyncingType: true,
              accountDisabled: true,
              photo: true,
              monthlyAdvance: true,
              monthlySalary: true,
              contractType: true,
              arrivedAt: true,
              leavedAt: true,
              remunerationGuaranteedPercentage: true,
              remunerationGuaranteeEndDate: true,
              remunerationT1Threshold: true,
              remunerationT1Percentage: true,
              remunerationT2Threshold: true,
              remunerationT2Percentage: true,
              remunerationT3Percentage: true,
              hr_remunerationComment: true,
              hr_interviewedHR: true,
              hr_interviewedArsene: true,
              hr_interviewedArthur: true,
              hr_interviewedManager: true,
              hr_onboardingCost: true,
              hr_offBoardingCost: true,
              hr_expectedTurnover12m: true,
              hr_yearsOfExperience: true,
              hr_previousEmployer: true,
              hr_stayingProbability: true,
              hr_trialPeriodEndDate: true,
              goals_comment: true,
              goals_weekComment: true,
              goals_quarterComment: true,
              goals_yearComment: true,
              goals_acceptedOffersHT: true,
              goals_madeOffers: true,
              goals_viewings: true,
              goals_successfulReminders: true,
              goals_successfulWhatsAppReminders: true,
              goals_mandates: true,
              goals_valuations: true,
              goals_prospectedStreets: true,
              vipContactsLimit: true,
              buyerContactsLimit: true,
              silae_EId: true,
            },
          }, UserFields],
        },
      }),
    },
  }, args);
}
