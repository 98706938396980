import type { IdType } from '@repo-breteuil/common-definitions';
import type { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';
import type {
  MyBreteuilUserContractTypeEnum,
  MyBreteuilUserAddressBookSyncingType,
} from '@core/api/types';

import { paginationResultAsList } from '@core/api/graphql/gen-v2';
import { PictureFormat } from '@core/api/types';

export interface SalaryInfo
{
  year: number,
  month: number,
  advanceOwed: number | null,
  advanceKept: number | null,
  advanceDropped: number | null,
  remunerationPercentage: number | null,
  comment: string | null,
}

export interface User
{
  id: IdType,
  cpRemuneration: number | null,
  // - HR
  admin: boolean,
  manager: boolean,
  isAgencyPotCommun: boolean,
  monthlyAdvance: number | null,
  monthlySalary: number | null,
  contractType: MyBreteuilUserContractTypeEnum | null,
  arrivedAt: number | null/*timestamp*/,
  leavedAt: number | null/*timestamp*/,
  remunerationGuaranteedPercentage: number | null,
  remunerationGuaranteeEndDate: number | null/*timestamp*/,
  remunerationT1Threshold: number | null,
  remunerationT1Percentage: number | null,
  remunerationT2Threshold: number | null,
  remunerationT2Percentage: number | null,
  remunerationT3Percentage: number | null,
  hr_remunerationComment: string | null,
  hr_interviewedHR: boolean | null,
  hr_interviewedArsene: boolean | null,
  hr_interviewedArthur: boolean | null,
  hr_interviewedManager: boolean | null,
  hr_onboardingCost: number | null,
  hr_offBoardingCost: number | null,
  hr_expectedTurnover12m: number | null,
  hr_yearsOfExperience: number | null,
  hr_previousEmployer: string | null,
  hr_stayingProbability: number | null,
  hr_trialPeriodEndDate: number | null/*timestamp*/,
  hr_photoFileUploadId: IdType | null,
  photoURL: string | null,
  // - IT
  agencies: Array<{
    id: IdType,
    name: string,
  }>,
  login: string | null,
  firstname: string | null,
  lastname: string | null,
  fullnameReversed: string,
  email: string | null,
  phoneMobile: string | null,
  phoneDirectLine: string | null,
  phoneFallbackAgency?: string | null,
  phoneFallbackPrestige?: string | null,
  remoteAccess: boolean,
  remoteAccessUntil: number | null/*timestamp*/,
  addressBookSyncingType: MyBreteuilUserAddressBookSyncingType | null,
  accountDisabled: boolean,
  // - Monthly goals
  goals_comment: string | null,
  goals_weekComment: string | null,
  goals_quarterComment: string | null,
  goals_yearComment: string | null,
  goals_acceptedOffersHT: number | null,
  goals_madeOffers: number | null,
  goals_viewings: number | null,
  goals_successfulReminders: number | null,
  goals_successfulWhatsAppReminders: number | null,
  goals_mandates: number | null,
  goals_valuations: number | null,
  goals_prospectedStreets: number | null,
  vipContactsLimit: number,
  buyerContactsLimit: number | null,
  // - Accounting
  salaryInfo: Array<SalaryInfo>,
  dayOffBalances: Array<{
    year: number,
    paidLeaveDays: number | null,
    toilDays: number | null,
  }>,
  silae_EId: string | null,
  contactSearchAgency: {
    id: IdType,
    companyInfo: {
      name: string | null,
    } | null,
  } | null,
}

export const UserFields: ReadonlyNodeFields = {
  id: true,
  cpRemuneration: true,
  // - HR
  admin: true,
  manager: true,
  isAgencyPotCommun: true,
  monthlyAdvance: true,
  monthlySalary: true,
  contractType: true,
  arrivedAt: true,
  leavedAt: true,
  remunerationGuaranteedPercentage: true,
  remunerationGuaranteeEndDate: true,
  remunerationT1Threshold: true,
  remunerationT1Percentage: true,
  remunerationT2Threshold: true,
  remunerationT2Percentage: true,
  remunerationT3Percentage: true,
  hr_remunerationComment: [{ aliasedField: 'hrRemunerationComment' }, true],
  hr_interviewedHR: [{ aliasedField: 'hrInterviewedHR' }, true],
  hr_interviewedArsene: [{ aliasedField: 'hrInterviewedArsene' }, true],
  hr_interviewedArthur: [{ aliasedField: 'hrInterviewedArthur' }, true],
  hr_interviewedManager: [{ aliasedField: 'hrInterviewedManager' }, true],
  hr_onboardingCost: [{ aliasedField: 'hrOnboardingCost' }, true],
  hr_offBoardingCost: [{ aliasedField: 'hrOffBoardingCost' }, true],
  hr_expectedTurnover12m: [{ aliasedField: 'hrExpectedTurnover12m' }, true],
  hr_yearsOfExperience: [{ aliasedField: 'hrYearsOfExperience' }, true],
  hr_previousEmployer: [{ aliasedField: 'hrPreviousEmployer' }, true],
  hr_stayingProbability: [{ aliasedField: 'hrStayingProbability' }, true],
  hr_trialPeriodEndDate: [{ aliasedField: 'hrTrialPeriodEndDate' }, true],
  hr_photoFileUploadId: [{ aliasedField: 'hrPhotoFileUploadId' }, true],
  photoURL: [{ args: { format: { literal: PictureFormat._480p } } }, true],
  // - IT
  agencies: paginationResultAsList({
    id: true,
    name: true,
  }),
  login: true,
  firstname: true,
  lastname: true,
  fullnameReversed: true,
  email: true,
  phoneMobile: true,
  phoneDirectLine: true,
  phoneFallbackAgency: true,
  phoneFallbackPrestige: true,
  remoteAccess: true,
  remoteAccessUntil: true,
  addressBookSyncingType: true,
  accountDisabled: true,
  // - Monthly goals
  goals_comment: [{ aliasedField: 'goalsComment' }, true],
  goals_weekComment: [{ aliasedField: 'goalsWeekComment' }, true],
  goals_quarterComment: [{ aliasedField: 'goalsQuarterComment' }, true],
  goals_yearComment: [{ aliasedField: 'goalsYearComment' }, true],
  goals_acceptedOffersHT: [{ aliasedField: 'goalsAcceptedOffersHT' }, true],
  goals_madeOffers: [{ aliasedField: 'goalsMadeOffers' }, true],
  goals_viewings: [{ aliasedField: 'goalsViewings' }, true],
  goals_successfulReminders: [{ aliasedField: 'goalsSuccessfulReminders' }, true],
  goals_successfulWhatsAppReminders: [{ aliasedField: 'goalsSuccessfulWhatsAppReminders' }, true],
  goals_mandates: [{ aliasedField: 'goalsMandates' }, true],
  goals_valuations: [{ aliasedField: 'goalsValuations' }, true],
  goals_prospectedStreets: [{ aliasedField: 'goalsProspectedStreets' }, true],
  vipContactsLimit: true,
  buyerContactsLimit: true,
  // - Accounting
  salaryInfo: paginationResultAsList({
    year: true,
    month: true,
    advanceOwed: true,
    advanceKept: true,
    advanceDropped: true,
    remunerationPercentage: true,
    comment: true,
  }),
  dayOffBalances: paginationResultAsList({
    year: true,
    paidLeaveDays: true,
    toilDays: true,
  }),
  silae_EId: [{ aliasedField: 'silaeEId' }, true],
  contactSearchAgency: {
    id: true,
    companyInfo: [{
      preventUnwrap: true,
    }, {
      name: true,
    }],
  },
};
